import styled ,{css, keyframes} from 'styled-components';
import main_photo from "./imgs/main.jpg"
import main_photo_mobile from "./imgs/main_mobile.jpg"
import st_andrews from "./imgs/st_andrews.png";
import fettes from "./imgs/fettes.png"
import AI_course from "./imgs/AI_course.jpg"
import blockchain_course from "./imgs/blockchain_course.jpg"
import react_course from "./imgs/react_course.jpg"
import gear from "./imgs/gear.png"
import linkedin from "./imgs/linkedin.png"
import email from "./imgs/email.png"
import phone from "./imgs/phone.png"
import salsa from "./imgs/salsa.jpg"
import guitar from "./imgs/guitar.jpg"
import coocking from "./imgs/coocking.jpg"
import contact_me_pic from "./imgs/contact_me_pic.jpg"
import contact_me_pic_mobile from "./imgs/contact_me_pic_mobile.jpg"
import fife_taxis from "./imgs/fife_taxis.png"
import { createGlobalStyle } from 'styled-components';
import React, { useEffect, useState,useRef, useCallback } from 'react';
import { HamburgerSpin } from 'react-animated-burgers'
import AOS from 'aos';
import 'aos/dist/aos.css';



function App() {
  const [scrollY, setScrollY] = useState(0);
  const skill_sec=useRef(null)
  const extr_cu_sec=useRef(null)
  const about_me_sec=useRef(null)
  const contact_sec=useRef(null)
  const education_sec=useRef(null)
  const [isMounted,setIsMounted]=useState(false);
  const [isApproachedSkills,setIsApproachedSkills]=useState(false)
  const [isScrolledExtraCurr,setIsScrolledExtraCurr]=useState(false)
  const [isScrolledContactMe,setIsScrolledContactMe]=useState(false)
  // Function to handle the scroll event
  const handleScroll = () => {
    setScrollY(window.scrollY);
    setIsApproachedSkills(skill_sec.current.getBoundingClientRect().y-window.innerHeight*0.25<=0)
    setIsScrolledExtraCurr(extr_cu_sec.current.getBoundingClientRect().y-window.innerHeight*0.75<=0)
    setIsScrolledContactMe(contact_sec.current.getBoundingClientRect().y-window.innerHeight*0.75<=0)
  }
  
  useEffect(() => {
    AOS.init();    
    window.addEventListener('scroll', handleScroll);
    setIsMounted(true)
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the threshold as needed
    };

    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [isStartedToggle,setIsStartedToggle]=useState(false);
  useEffect(()=>{
    if(isMounted){
      setInterval(()=>{
        setRotateCourseCube(prev=>prev-1)
      },4000)
    }
  },[isMounted])
  
  const [isMobile,setIsMobile]=useState(window.innerWidth<=767)
  const aboutPosition = isMobile? window.innerHeight*0.5: window.innerHeight*0.5;
  const isScrolledAbout = scrollY >= aboutPosition;
  

  const [rotateCourseCube,setRotateCourseCube]=useState(0)
  const [imagesForCourseCube,setImagesForCourseCube]=useState([AI_course,react_course,blockchain_course])
  const [courseCubeImagesQueue,setCourseCubeImagesQueue]=useState([AI_course,react_course,blockchain_course,AI_course])


  useEffect(()=>{
    /////////////////////////////Needs work/////////////////////////
    if(isMounted){
      let pos=Math.abs(rotateCourseCube+1)%4
      let current_image=imagesForCourseCube[(imagesForCourseCube.indexOf(courseCubeImagesQueue[pos])+1)%imagesForCourseCube.length]
      // console.log(Math.abs(rotateCourseCube)%3)
      // console.log(current_image)
      let index_to_put=Math.abs((pos+imagesForCourseCube.length+1-4)%4)
      // console.log(index_to_put)
      // console.log(courseCubeImagesQueue)
      courseCubeImagesQueue[index_to_put]=current_image
      // console.log(courseCubeImagesQueue)
    }
  },[rotateCourseCube])

  const [isActive, setIsActive] = useState(false)

  const toggleButton = useCallback(
    () => {
      setIsStartedToggle(true)
      setIsActive(prevState => !prevState)},
    [],
  )

  
  return (
    <>
      <GlobalStyle/>
      <StartContainer image_back={main_photo} isScrolledAbout={isScrolledAbout} >
        <BackgroundImage main_photo_mobile={main_photo_mobile} image_back={main_photo} isScrolledAbout={isScrolledAbout} />
        {isMobile?
        <>
          <div style={{flex:1,display:'flex',alignItems:'start',justifyContent:'end'}}> {/*for navigation */}
              <MenuContainer isStartedToggle={isStartedToggle} isToggled={isActive}>
                <span onClick={()=>{
                  setIsActive(prevState => !prevState)
                  window.scrollTo({
                    top: about_me_sec.current.getBoundingClientRect().y,
                    behavior: 'smooth' // You can use 'auto' for immediate scrolling
                  });
                }} style={{cursor: 'pointer',fontWeight:'bold',fontSize:'2vh',color:'white'}}>About Me</span>
                <span onClick={()=>{
                  setIsActive(prevState => !prevState)
                  window.scrollTo({
                    top: education_sec.current.getBoundingClientRect().y,
                    behavior: 'smooth' // You can use 'auto' for immediate scrolling
                  });
                }} style={{cursor: 'pointer',fontWeight:'bold',fontSize:'2vh',color:'white'}}>Education</span>
                <span onClick={()=>{
                  setIsActive(prevState => !prevState)
                  window.scrollTo({
                    top: skill_sec.current.getBoundingClientRect().y,
                    behavior: 'smooth' // You can use 'auto' for immediate scrolling
                  });
                }} style={{cursor: 'pointer',fontWeight:'bold',fontSize:'2vh',color:'white'}}>Professional Skills</span>
                <span onClick={()=>{
                  setIsActive(prevState => !prevState)
                  window.scrollTo({
                    top: extr_cu_sec.current.getBoundingClientRect().y,
                    behavior: 'smooth' // You can use 'auto' for immediate scrolling
                  });
                }} style={{cursor: 'pointer',fontWeight:'bold',fontSize:'2vh',color:'white'}}>Extracurricular</span>
                <span onClick={()=>{
                  setIsActive(prevState => !prevState)
                  window.scrollTo({
                    top: contact_sec.current.getBoundingClientRect().y,
                    behavior: 'smooth' // You can use 'auto' for immediate scrolling
                  });
                }} style={{cursor: 'pointer',fontWeight:'bold',fontSize:'2vh',color:'white'}}>Contact Me</span>
              </MenuContainer>
              <HamburgerSpin
                buttonStyle={{ marginTop: "5vw",marginLeft: "1vw" ,marginRight: "0vw"}}
                buttonWidth={window.innerWidth*0.08}
                barColor="white"
                {...{ isActive, toggleButton }}
              />
          </div>
          <div style={{backgroundColor:'',flex:2,display:'flex',justifyContent:'center',flexDirection:'column',padding:'5vw',gap:'2vh'}}>
          <span style={{fontSize:isMobile?'4vh':'5vh',fontWeight:''}}>Software Engineer</span>
          <span style={{fontSize:isMobile? '6vh':'7vh',fontWeight:'bold'}}>Leonid Nosovitskiy.</span>
          <span style={{fontSize:isMobile? '2vh':'3vh',fontWeight:''}}>Strategist&emsp;|&emsp;Team Player&emsp;|&emsp;Solutionist</span>
          </div>
        </>
        :
        <>
          <div style={{backgroundColor:'',flex:1,display:'flex',justifyContent:'center',flexDirection:'column',padding:'5vw',gap:'2vh'}}>
            <span style={{fontSize:isMobile?'4vh':'5vh',fontWeight:''}}>Software Engineer</span>
            <span style={{fontSize:isMobile? '6vh':'7vh',fontWeight:'bold'}}>Leonid Nosovitskiy.</span>
            <span style={{fontSize:isMobile? '2vh':'3vh',fontWeight:''}}>Strategist&emsp;|&emsp;Team Player&emsp;|&emsp;Solutionist</span>
          </div>
          <div style={{flex:1,display:'flex',alignItems:'start',justifyContent:'end'}}> {/*for navigation */}
              <MenuContainer isStartedToggle={isStartedToggle} isToggled={isActive}>
                <span onClick={()=>{
                  setIsActive(prevState => !prevState)
                  window.scrollTo({
                    top: about_me_sec.current.getBoundingClientRect().y,
                    behavior: 'smooth' // You can use 'auto' for immediate scrolling
                  });
                }} style={{cursor: 'pointer',fontWeight:'bold',fontSize:'3vh',color:'white'}}>About Me</span>
                <span onClick={()=>{
                  setIsActive(prevState => !prevState)
                  window.scrollTo({
                    top: education_sec.current.getBoundingClientRect().y,
                    behavior: 'smooth' // You can use 'auto' for immediate scrolling
                  });
                }} style={{cursor: 'pointer',fontWeight:'bold',fontSize:'3vh',color:'white'}}>Education</span>
                <span onClick={()=>{
                  setIsActive(prevState => !prevState)
                  window.scrollTo({
                    top: skill_sec.current.getBoundingClientRect().y,
                    behavior: 'smooth' // You can use 'auto' for immediate scrolling
                  });
                }} style={{cursor: 'pointer',fontWeight:'bold',fontSize:'3vh',color:'white'}}>Professional Skills</span>
                <span onClick={()=>{
                  setIsActive(prevState => !prevState)
                  window.scrollTo({
                    top: extr_cu_sec.current.getBoundingClientRect().y,
                    behavior: 'smooth' // You can use 'auto' for immediate scrolling
                  });
                }} style={{cursor: 'pointer',fontWeight:'bold',fontSize:'3vh',color:'white'}}>Extracurricular</span>
                <span onClick={()=>{
                  setIsActive(prevState => !prevState)
                  window.scrollTo({
                    top: contact_sec.current.getBoundingClientRect().y,
                    behavior: 'smooth' // You can use 'auto' for immediate scrolling
                  });
                }} style={{cursor: 'pointer',fontWeight:'bold',fontSize:'3vh',color:'white'}}>Contact Me</span>
              </MenuContainer>
              <HamburgerSpin
                buttonStyle={{ marginTop: "5vw",marginLeft: "2.5vw" ,marginRight: "5vw"}}
                buttonWidth={window.innerWidth*0.04}
                barColor="white"
                {...{ isActive, toggleButton }}
              />
          </div>
        </>
        }
      </StartContainer>
      <AboutContainer ref={about_me_sec} isScrolledAbout={isScrolledAbout}>
        <div id="left-about">
          <div>
            <span>About Me</span>
          </div>
          <div>
            <span>Academics.</span>
            <span>Expertise.</span>
            <span>Team Work.</span>
          </div>
          <div>
            <span>___________</span>
          </div>
        </div>
        <div id="right-about">
          <div>
            <span>Academic Excellence: &thinsp;</span>
            <span>As a final-year Computer Science student with a first-class standing, I've earned the Dean's List honor for three consecutive years, highlighting my dedication to academic achievement.</span>
          </div>
          <div>
            <span>Technical Proficiency: &thinsp;</span>
            <span>Specializing in full-stack development, I possess a broad skill set that encompasses front-end, back-end, blockchain, and even machine learning technologies.</span>
          </div>
          <div>
            <span>Lifelong Learner: &thinsp;</span>
            <span>Complementing my formal education, I've completed various online courses in emerging technologies, demonstrating my commitment to continuous learning.</span>
          </div>
          <div>
            <span>Language Expertise: &thinsp;</span>
            <span>I am well-versed in multiple programming languages, including JavaScript, SQL, Python, and more, offering flexibility across different technology stacks.</span>
          </div>
          <div>
            <span>Team Dynamics: &thinsp;</span>
            <span>My university years have been rich with opportunities to both lead and contribute to successful group projects, showcasing my adaptability as a results-driven individual in collaborative settings.</span>
          </div>
        </div>
      </AboutContainer>
      <EducationContainer ref={education_sec}>
        <div data-aos-duration="500" data-aos="flip-up"  style={{display:'flex',flexDirection:'column'}}>
          <span>Education</span>
          <span>___________</span>
        </div>
        <StAndrews>
          {isMobile&&
            <div data-aos-offset={window.innerHeight*0.1} data-aos-duration="1000" data-aos="slide-left" data-aos-easing="ease-out"  data-aos-mirror="false" >
              <img  alt='st-andrews logo' src={st_andrews}/>
            </div>
          }
          <div className='education-info'>
            <span data-aos-offset={window.innerHeight*0.1} data-aos-duration="500" data-aos="fade-right" data-aos-mirror="true">BSc (Hons) Computer Science (2020-2024)</span>
            <span data-aos-offset={window.innerHeight*0.1} data-aos-duration="500" data-aos="fade-right" data-aos-mirror="true">4th year</span>
            <ul data-aos-offset={window.innerHeight*0.1} data-aos-duration="500" data-aos="fade-right" data-aos-mirror="true">
              <li><span>Major Software Project</span></li>
              <li><span>Computer Security</span></li>
              <li><span>Signal Processing: Sound, Image, Video</span></li>
              <li><span>Principles of Computer Communication Systems</span></li>
              <li><span>Concurrency and Multi-Core Architectures</span></li>
              <li><span>Artificial Intelligence Practice</span></li>
              <li><span>Machine Learning</span></li>
            </ul>
            <span data-aos-offset={window.innerHeight*0.1} data-aos-duration="500" data-aos="fade-right" data-aos-mirror="true">3rd year</span>
            <ul data-aos-offset={window.innerHeight*0.1} data-aos-duration="500" data-aos="fade-right" data-aos-mirror="true">
              <li><span>Software Engineering Team Project</span></li>
              <li><span>Human Computer Interaction</span></li>
              <li><span>Databases</span></li>
              <li><span>Computational Complexity</span></li>
              <li><span>Operating Systems</span></li>
              <li><span>Logic and Reasoning</span></li>
              <li><span>Artificial Intelligence</span></li>
            </ul>
            <span data-aos-offset={window.innerHeight*0.1} data-aos-duration="500" data-aos="fade-right" data-aos-mirror="true">2nd year</span>
            <ul data-aos-offset={window.innerHeight*0.1} data-aos-duration="500" data-aos="fade-right" data-aos-mirror="true">
              <li><span>Foundations of Computation</span></li>
              <li><span>The Internet and the Web: Concepts and Programming</span></li>
              <li><span>Advanced Programming Projects</span></li>
              <li><span>Computer Systems</span></li>
            </ul>
            <span data-aos-offset={window.innerHeight*0.1} data-aos-duration="500" data-aos="fade-right" data-aos-mirror="true">1st year</span>
            <ul data-aos-offset={window.innerHeight*0.1} data-aos-duration="500" data-aos="fade-right" data-aos-mirror="true">
              <li><span>Object-Oriented Programming</span></li>
              <li><span>Programming with Data</span></li>
              <li><span>Programming Projects</span></li>
              <li><span>Statistics in Practice</span></li>
              <li><span>Mathematics</span></li>
              <li><span>Microeconomics</span></li>
            </ul>
          </div>
          {!isMobile&&
            <div data-aos-offset={window.innerHeight*0.1} data-aos-duration="1000" data-aos="slide-left" data-aos-easing="ease-out"  data-aos-mirror="false" >
              <img  alt='st-andrews logo' src={st_andrews}/>
            </div>
          }
        </StAndrews>
        <Fettes>
          <div data-aos-offset={window.innerHeight*0.1} data-aos-duration="1000"  data-aos="slide-right"  data-aos-easing="ease-out"  data-aos-mirror="false">
            <img  alt='fettes college logo' src={fettes}/>
          </div>
          <div className='education-info'>
            <span data-aos-offset={window.innerHeight*0.1} data-aos-duration="500" data-aos="fade-left" data-aos-mirror="true">International Baccalaureate (2018-2020)</span>
            <span data-aos-offset={window.innerHeight*0.1} data-aos-duration="500" data-aos="fade-left" data-aos-mirror="true">Higher Level Subjects</span>
            <ul data-aos-offset={window.innerHeight*0.1} data-aos-duration="500" data-aos="fade-left" data-aos-mirror="true">
              <li><span>Computer Science</span></li>
              <li><span>Mathematics</span></li>
              <li><span>Physics</span></li>
            </ul>
            <span data-aos-offset={window.innerHeight*0.1} data-aos-duration="500" data-aos="fade-left" data-aos-mirror="true">Standard Level Subjects</span>
            <ul data-aos-offset={window.innerHeight*0.1} data-aos-duration="500" data-aos="fade-left" data-aos-mirror="true">
              <li><span>English Literature</span></li>
              <li><span>Economics</span></li>
              <li><span>German Language</span></li>
            </ul>
          </div>
        </Fettes>
        
      </EducationContainer>

      <BlackScreen isScrolledExtraCurr={isScrolledExtraCurr} isApproachedSkills={isApproachedSkills}>
      <FixedGear alt={"gear"} src={gear} style={
        isMobile? {left:"-10vw",top:"5vw",width:'40vw',height:'40vw'}:
        {left:"-15vh",top:"-10vh",width:'50vh',height:'50vh'}}/>
      <FixedGear alt={"gear"} src={gear} style={
        isMobile?
        {left:"15vw",top:"-5vw",width:'40vw',height:'40vw'}:
        {left:"150vh",top:"-10vh",width:'50vh',height:'50vh'}}/>
       <FixedGear alt={"gear"} src={gear} style={
        isMobile?
        {left:"45vw",top:"-5vw",width:'40vw',height:'40vw'}:
        {left:"30vh",top:"-25vh",width:'50vh',height:'50vh'}}/>
       <FixedGear alt={"gear"} src={gear} style={
        isMobile?
        {left:"70vw",top:"5vw",width:'40vw',height:'40vw'}:
        {left:"110vh",top:"-25vh",width:'50vh',height:'50vh'}}/>
      {!isMobile&&<FixedGear alt={"gear"} src={gear} style={{left:"70vh",top:"-30vh",width:'50vh',height:'50vh'}}/>}
      
      {new Array(Math.floor(window.innerWidth/(window.innerWidth*0.03))).fill(0).map(()=>{
        return <RandomGear/>
      })}
    
      </BlackScreen>
      <GradientScreen isScrolledContactMe={isScrolledContactMe} isScrolledExtraCurr={isScrolledExtraCurr}/>
      <ContactScreen contact_me_pic_mobile={contact_me_pic_mobile} image={contact_me_pic} isScrolledContactMe={isScrolledContactMe}/>
      <SkillsContainer isScrolledExtraCurr={isScrolledExtraCurr} isApproachedSkills={isApproachedSkills}  ref={skill_sec}>
        <div>
          <span>Professional Skills</span>
          <span>Dedicated to Continual Learning and Mastery in the Ever-Changing Landscape of Technology.</span>
          <span>___________</span>
        </div>
        {isMobile?
        <>
          <div>
            <div>
              <span>Coding Languages in Proficiency</span>
            </div>
            <div>
              <ul>
                <li><span>Java</span></li>
                <li><span>JavaScript</span></li>
                <li><span>C</span></li>
                <li><span>HTML&CSS</span></li>
                <li><span>Python</span></li>
                <li><span>SQL</span></li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <span>Libraries, Frameworks & Technologies</span>
            </div>
            <div>
              <ul>
                <li><span>Node.js</span></li>
                <li><span>React & React Native</span></li>
                <li><span>Express</span></li>
                <li><span>NoSQL (MongoDB, Mongoose)</span></li>
                <li><span>Keras & Tensorflow</span></li>
                <li><span>Linux</span></li>
                <li><span>AWS</span></li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <span>Methodologies</span> 
            </div>
            <div>
              <ul>
                <li><span>Test Driven Development</span></li>
                <li><span>Agile (Scrum) Methodologies</span></li>
                <li><span>Version Control and Collaboration</span></li>
                <li><span>Code Review and Refactoring</span></li>
                <li><span>Technical Documentation Writing</span></li>
                <li><span>Continuous Integration and Deployment (CI/CD)</span></li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <span>Development Types</span>
            </div>
            <div>
              <ul>
                <li><span>Backend & API</span></li>
                <li><span>Web</span></li>
                <li><span>Android Apps (Java)</span></li>
                <li><span>Mobile Apps (React Native)</span></li>
                <li><span>Database Development</span></li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <span>Transfered Skills</span>
            </div>
            <div>
              <ul>
                <li><span>Remote Work Skills</span></li>
                <li><span>Negotiation Skills</span></li>
                <li><span>Problem Debugging</span></li>
                <li><span>Adaptability</span></li>
                <li><span>Leadership Skills</span></li>
                <li><span>Time Management</span></li>
                <li><span>Problem Solving</span></li>
              </ul>
            </div>
          </div>
        </>
        :
        <>
          <div>
            <div>
              <span>Coding Languages in Proficiency</span>
            </div>
            <div>
              <span>Libraries, Frameworks & Technologies</span>
            </div>
            <div>
              <span>Methodologies</span> 
            </div>
          </div>
          <div>
            <div>
              <ul>
                <li><span>Java</span></li>
                <li><span>JavaScript</span></li>
                <li><span>C</span></li>
                <li><span>HTML&CSS</span></li>
                <li><span>Python</span></li>
                <li><span>SQL</span></li>
              </ul>
            </div>
            <div>
              <ul>
                <li><span>Node.js</span></li>
                <li><span>React & React Native</span></li>
                <li><span>Express</span></li>
                <li><span>NoSQL (MongoDB, Mongoose)</span></li>
                <li><span>Keras & Tensorflow</span></li>
                <li><span>Linux</span></li>
                <li><span>AWS</span></li>
              </ul>
            </div>
            <div>
              <ul>
                <li><span>Test Driven Development</span></li>
                <li><span>Agile (Scrum) Methodologies</span></li>
                <li><span>Version Control and Collaboration</span></li>
                <li><span>Code Review and Refactoring</span></li>
                <li><span>Technical Documentation Writing</span></li>
                <li><span>Continuous Integration and Deployment (CI/CD)</span></li>
              </ul>
            </div>
          </div>
          <div>
            <div>
              <span>Development Types</span>
            </div>
            <div>
              <span>Transfered Skills</span>
            </div>
            <div/>
          </div>
          <div>
            <div>
              <ul>
                <li><span>Backend & API</span></li>
                <li><span>Web</span></li>
                <li><span>Android Apps (Java)</span></li>
                <li><span>Mobile Apps (React Native)</span></li>
                <li><span>Database Development</span></li>
              </ul>
            </div>
            <div>
              <ul>
                <li><span>Remote Work Skills</span></li>
                <li><span>Negotiation Skills</span></li>
                <li><span>Problem Debugging</span></li>
                <li><span>Adaptability</span></li>
                <li><span>Leadership Skills</span></li>
                <li><span>Time Management</span></li>
                <li><span>Problem Solving</span></li>
              </ul>
            </div>
            <div/>
          </div>
        </>
        }
      </SkillsContainer>
      <ExtraCurricularContainer  ref={extr_cu_sec}>
        <div>
          <span>Extracurricular</span> 
          <span>Deploying Tomorrow, Today: My Life Outside the Terminal.</span>
          <span>___________</span> 
        </div>
        <div data-aos-duration="1000" data-aos="flip-up" data-aos-easing="ease-out"  data-aos-mirror="false"><span>Completed Online Courses</span></div>
        <div>
          <Cube>
            <ImageCube rotateCourseCube={rotateCourseCube}>
              <FrontImg>
                <img alt="cube-img1" src={courseCubeImagesQueue[0]} />
              </FrontImg>
              <RightImg>
                <img alt="cube-img2" src={courseCubeImagesQueue[1]} />
              </RightImg>
              <BackImg>
                <img alt="cube-img3" src={courseCubeImagesQueue[2]} />
              </BackImg>
              <LeftImg>
                <img alt="cube-img4" src={courseCubeImagesQueue[3]} />
              </LeftImg>
            </ImageCube>
          </Cube>
          <CourseInfoContainer>
            <CourseInfo x={0} show_course={(Math.abs(rotateCourseCube)%3)===0} >
              <span>Deep Learning A-Z™ 2023: Neural Networks, AI & ChatGPT Bonus</span>
              <a href="https://www.udemy.com/course/deeplearning/"><span>https://www.udemy.com/course/deeplearning/</span></a>
            </CourseInfo>
            <CourseInfo x={1} show_course={(Math.abs(rotateCourseCube)%3)===1} >
              <span>Complete React Native in 2023: Zero to Mastery (with Hooks)</span>
              <a href="https://www.udemy.com/course/complete-react-native-mobile-development-zero-to-mastery-with-hooks/"><span>https://www.udemy.com/course/complete-react-native-mobile-development-zero-to-mastery-with-hooks/</span></a>
            </CourseInfo>
            <CourseInfo x={2}  show_course={(Math.abs(rotateCourseCube)%3)===2} >
              <span>Blockchain A-Z™: Learn Blockchain, Cryptos & Smart Contracts</span>
              <a href="https://www.udemy.com/course/build-your-blockchain-az/"><span>https://www.udemy.com/course/build-your-blockchain-az/</span></a>
            </CourseInfo>
          </CourseInfoContainer>
        </div>
        <div data-aos-duration="1000" data-aos="flip-up" data-aos-easing="ease-out"  data-aos-mirror="false"><span>Personal Project</span></div>
        <div>
          <img  alt="fife-taxis-logo" src={fife_taxis}/>
          
          <div>
            <span>Fife Taxis Mobile App</span>
            <span>Developed a React-Native app with a partner to provide taxi rides and taxi companies cross-communication in StAndrews.</span>
            <span>Included additional unique features such as: exchanging rides between taxi drivers, finding other passengers to share a transfer.</span>
            <span>Created frontend, backend and managed database.</span>
          </div>
        </div>
        <div data-aos-duration="1000" data-aos="flip-up" data-aos-easing="ease-out"  data-aos-mirror="false"> <span>Hobbies</span></div>
        <div>
          <div data-aos-duration="1000" data-aos={isMobile? "fade-down-left":"fade-up-right"} data-aos-easing="ease-out"  data-aos-mirror="false">
            <img alt="salsa dancing" src={salsa}/>
            <span>Salsa & Bachata Dancing</span>
          </div>
          <div data-aos-duration="1000" data-aos={isMobile? "fade-left":"fade-up"} data-aos-easing="ease-out"  data-aos-mirror="false">
            <img alt="guitar playing" src={guitar}/>
            <span>Guitar Playing</span>
          </div>
          <div data-aos-duration="1000" data-aos={isMobile? "fade-up-left":"fade-up-left"} data-aos-easing="ease-out"  data-aos-mirror="false">
            <img alt="coocking" src={coocking}/>
            <span>Cooking</span>
          </div>
        </div>
      </ExtraCurricularContainer>
      <ContactMeContainer ref={contact_sec}>
        <div   style={{display:'flex',flexDirection:'column'}}>
          <span>Contact Me</span>
          <span>___________</span>
        </div>
        <div>
          <div ><img alt="email" src={email}/>
            {isMobile?
              <div>
                <span>leonid.nosovitskiy@gmail.com</span>
              </div>
              :
              <span>leonid.nosovitskiy@gmail.com</span>
            }
            </div>
          <div><img alt="phone" src={phone}/>
          {isMobile?
              <div>
                <span>+44 7460 027861</span>
              </div>
              :
              <span>+44 7460 027861</span>
            }</div>
          <div><img alt="linkedin" src={linkedin}/><a href='https://www.linkedin.com/in/leonid-nosovitskiy-277335237/'>
          {isMobile?
              <div>
                <span>https://www.linkedin.com/in/leonid-nosovitskiy-277335237/</span>
              </div>
              :
              <span>https://www.linkedin.com/in/leonid-nosovitskiy-277335237/</span>
            }
          </a></div>
        </div>
      </ContactMeContainer>
    </>
  );
}

export default App;
const menu_anim_in=keyframes`
  0%{
    opacity:0;
    transform:rotate(90deg) translate(-35vh,-30vw);
  }
  100%{
    opacity:1;
    transform:rotate(0deg) translate(0,0);
  }
`

const menu_anim_out=keyframes`
  0%{
    opacity:1;
    transform:rotate(0deg) translate(0,0);
  }
  100%{
    opacity:0;
    transform:rotate(90deg) translate(-35vh,-30vw);
  }
`
const MenuContainer=styled.div`
  opacity:0;
  transform:rotate(90deg) translate(-35vh,-30vw);
  flex-direction:column;
  gap:3vh;
  display:flex;
  margin-top:10vw;
  animation: ${({isStartedToggle}) => isStartedToggle?  css`${props=>!props.isToggled ? menu_anim_out : menu_anim_in} 1s ease forwards`:`none`};
  
`

const ContactMeContainer=styled.div`
  position:relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-bottom:70vh;
  span{
    color:white;
  }
  

  >div:first-of-type {
    display:flex;
    justify-content:center;
    @media screen and (min-width: 768px) {
      margin-left: 10vw;
      margin-right: 10vw;
    }
    @media screen and (max-width: 767px) {
      margin-left: 5vw;
      margin-right: 5vw;
    }
    margin-bottom: 5vw;
    span{
      @media screen and (min-width: 768px) {
        font-size:6vh;
      }
      @media screen and (max-width: 767px) {
        font-size:5vh;
      }
    }
  }
  >div:nth-of-type(2) {
    display:flex;
    flex-direction:column;
    @media screen and (min-width: 768px) {
      margin-left: 10vw;
      margin-right: 10vw;
      gap:3vh;
    }
    @media screen and (max-width: 767px) {
      margin-left: 5vw;
      margin-right: 5vw;
      gap:2vh;
    }
    div {
      display:flex;
      align-items:center;
      img{
        @media screen and (min-width: 768px) {
          width:5vw;
          height:5vw;
        }
        @media screen and (max-width: 767px) {
          width:10vw;
          height:10vw;
        }
      }
      a{
        text-decoration-color: white;
      }
      @media screen and (min-width: 768px) {
          span{
            font-size:3vh;
            margin-left:3vw;
          }
      }
      @media screen and (max-width: 767px) {
        div{
          margin-left:3vw;
          span{
            font-size:2vh;
          }
        }
      }
    }
  }
`

const FrontImg=styled.div`
  @media screen and (min-width: 768px) {
    transform: translateZ(10vw);
  }
  @media screen and (max-width: 767px) {
    transform: translateZ(35vw);
  }
`
const RightImg=styled.div`
  @media screen and (min-width: 768px) {
    transform: rotateY(-270deg) translateX(10vw);
  }
  @media screen and (max-width: 767px) {
    transform: rotateY(-270deg) translateX(35vw);
  }
  transform-origin: 100% 0;
`
const BackImg=styled.div`
  @media screen and (min-width: 768px) {
    transform: translateZ(-10vw) rotateY(180deg); 
  }
  @media screen and (max-width: 767px) {
    transform: translateZ(-35vw) rotateY(180deg); 
  } 
`
const LeftImg=styled.div`
  @media screen and (min-width: 768px) {
    transform: rotateY(270deg) translateX(-10vw);
  }
  @media screen and (max-width: 767px) {
    transform: rotateY(270deg) translateX(-35vw);
  }
  transform-origin: 0 50%;
`
const Cube=styled.div`
  @media screen and (min-width: 768px) {
    height: 20vw;
    width: 20vw;
  }
  @media screen and (max-width: 767px) {
    height: 70vw;
    width: 70vw;
  }
  perspective: 120vh;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective-origin: 50%;
`

const ImageCube=styled.div`
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  position: relative;
  transform:${props=>`rotateY(${props.rotateCourseCube*90}deg)`};
  transition: 2s;
  -webkit-transition: 2s;


  div {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color:rgba(0, 0, 0, 1);
    display:flex;
    flex-direction:column;
    justify-content:center;
  }
  img {
    width: 100%;
    transform: translateZ(0);
  }
`

const GradientAnimation=keyframes`

0% {
  background:linear-gradient(45deg, black 45%, 	rgb(105,105,105) 50%, black 55% );
}    

0.5% {
  background:linear-gradient(45deg, black 44.95%, 	rgb(105,105,105) 50%, black 55.05% );
}    

1% {
  background:linear-gradient(45deg, black 44.9%, 	rgb(105,105,105) 50%, black 55.1% );
}    

1.5% {
  background:linear-gradient(45deg, black 44.85%, 	rgb(105,105,105) 50%, black 55.15% );
}    

2% {
  background:linear-gradient(45deg, black 44.8%, 	rgb(105,105,105) 50%, black 55.2% );
}    

2.5% {
  background:linear-gradient(45deg, black 44.75%, 	rgb(105,105,105) 50%, black 55.25% );
}    

3% {
  background:linear-gradient(45deg, black 44.7%, 	rgb(105,105,105) 50%, black 55.3% );
}    

3.5% {
  background:linear-gradient(45deg, black 44.65%, 	rgb(105,105,105) 50%, black 55.35% );
}    

4% {
  background:linear-gradient(45deg, black 44.6%, 	rgb(105,105,105) 50%, black 55.4% );
}    

4.5% {
  background:linear-gradient(45deg, black 44.55%, 	rgb(105,105,105) 50%, black 55.45% );
}    

5% {
  background:linear-gradient(45deg, black 44.5%, 	rgb(105,105,105) 50%, black 55.5% );
}    

5.5% {
  background:linear-gradient(45deg, black 44.45%, 	rgb(105,105,105) 50%, black 55.55% );
}    

6% {
  background:linear-gradient(45deg, black 44.4%, 	rgb(105,105,105) 50%, black 55.6% );
}    

6.5% {
  background:linear-gradient(45deg, black 44.35%, 	rgb(105,105,105) 50%, black 55.65% );
}    

7% {
  background:linear-gradient(45deg, black 44.3%, 	rgb(105,105,105) 50%, black 55.7% );
}    

7.5% {
  background:linear-gradient(45deg, black 44.25%, 	rgb(105,105,105) 50%, black 55.75% );
}    

8% {
  background:linear-gradient(45deg, black 44.2%, 	rgb(105,105,105) 50%, black 55.8% );
}    

8.5% {
  background:linear-gradient(45deg, black 44.15%, 	rgb(105,105,105) 50%, black 55.85% );
}    

9% {
  background:linear-gradient(45deg, black 44.1%, 	rgb(105,105,105) 50%, black 55.9% );
}    

9.5% {
  background:linear-gradient(45deg, black 44.05%, 	rgb(105,105,105) 50%, black 55.95% );
}    

10% {
  background:linear-gradient(45deg, black 44%, 	rgb(105,105,105) 50%, black 56% );
}    

10.5% {
  background:linear-gradient(45deg, black 43.95%, 	rgb(105,105,105) 50%, black 56.05% );
}    

11% {
  background:linear-gradient(45deg, black 43.9%, 	rgb(105,105,105) 50%, black 56.1% );
}    

11.5% {
  background:linear-gradient(45deg, black 43.85%, 	rgb(105,105,105) 50%, black 56.15% );
}    

12% {
  background:linear-gradient(45deg, black 43.8%, 	rgb(105,105,105) 50%, black 56.2% );
}    

12.5% {
  background:linear-gradient(45deg, black 43.75%, 	rgb(105,105,105) 50%, black 56.25% );
}    

13% {
  background:linear-gradient(45deg, black 43.7%, 	rgb(105,105,105) 50%, black 56.3% );
}    

13.5% {
  background:linear-gradient(45deg, black 43.65%, 	rgb(105,105,105) 50%, black 56.35% );
}    

14% {
  background:linear-gradient(45deg, black 43.6%, 	rgb(105,105,105) 50%, black 56.4% );
}    

14.5% {
  background:linear-gradient(45deg, black 43.55%, 	rgb(105,105,105) 50%, black 56.45% );
}    

15% {
  background:linear-gradient(45deg, black 43.5%, 	rgb(105,105,105) 50%, black 56.5% );
}    

15.5% {
  background:linear-gradient(45deg, black 43.45%, 	rgb(105,105,105) 50%, black 56.55% );
}    

16% {
  background:linear-gradient(45deg, black 43.4%, 	rgb(105,105,105) 50%, black 56.6% );
}    

16.5% {
  background:linear-gradient(45deg, black 43.35%, 	rgb(105,105,105) 50%, black 56.65% );
}    

17% {
  background:linear-gradient(45deg, black 43.3%, 	rgb(105,105,105) 50%, black 56.7% );
}    

17.5% {
  background:linear-gradient(45deg, black 43.25%, 	rgb(105,105,105) 50%, black 56.75% );
}    

18% {
  background:linear-gradient(45deg, black 43.2%, 	rgb(105,105,105) 50%, black 56.8% );
}    

18.5% {
  background:linear-gradient(45deg, black 43.15%, 	rgb(105,105,105) 50%, black 56.85% );
}    

19% {
  background:linear-gradient(45deg, black 43.1%, 	rgb(105,105,105) 50%, black 56.9% );
}    

19.5% {
  background:linear-gradient(45deg, black 43.05%, 	rgb(105,105,105) 50%, black 56.95% );
}    

20% {
  background:linear-gradient(45deg, black 43%, 	rgb(105,105,105) 50%, black 57% );
}    

20.5% {
  background:linear-gradient(45deg, black 42.95%, 	rgb(105,105,105) 50%, black 57.05% );
}    

21% {
  background:linear-gradient(45deg, black 42.9%, 	rgb(105,105,105) 50%, black 57.1% );
}    

21.5% {
  background:linear-gradient(45deg, black 42.85%, 	rgb(105,105,105) 50%, black 57.15% );
}    

22% {
  background:linear-gradient(45deg, black 42.8%, 	rgb(105,105,105) 50%, black 57.2% );
}    

22.5% {
  background:linear-gradient(45deg, black 42.75%, 	rgb(105,105,105) 50%, black 57.25% );
}    

23% {
  background:linear-gradient(45deg, black 42.7%, 	rgb(105,105,105) 50%, black 57.3% );
}    

23.5% {
  background:linear-gradient(45deg, black 42.65%, 	rgb(105,105,105) 50%, black 57.35% );
}    

24% {
  background:linear-gradient(45deg, black 42.6%, 	rgb(105,105,105) 50%, black 57.4% );
}    

24.5% {
  background:linear-gradient(45deg, black 42.55%, 	rgb(105,105,105) 50%, black 57.45% );
}    

25% {
  background:linear-gradient(45deg, black 42.5%, 	rgb(105,105,105) 50%, black 57.5% );
}    

25.5% {
  background:linear-gradient(45deg, black 42.45%, 	rgb(105,105,105) 50%, black 57.55% );
}    

26% {
  background:linear-gradient(45deg, black 42.4%, 	rgb(105,105,105) 50%, black 57.6% );
}    

26.5% {
  background:linear-gradient(45deg, black 42.35%, 	rgb(105,105,105) 50%, black 57.65% );
}    

27% {
  background:linear-gradient(45deg, black 42.3%, 	rgb(105,105,105) 50%, black 57.7% );
}    

27.5% {
  background:linear-gradient(45deg, black 42.25%, 	rgb(105,105,105) 50%, black 57.75% );
}    

28% {
  background:linear-gradient(45deg, black 42.2%, 	rgb(105,105,105) 50%, black 57.8% );
}    

28.5% {
  background:linear-gradient(45deg, black 42.15%, 	rgb(105,105,105) 50%, black 57.85% );
}    

29% {
  background:linear-gradient(45deg, black 42.1%, 	rgb(105,105,105) 50%, black 57.9% );
}    

29.5% {
  background:linear-gradient(45deg, black 42.05%, 	rgb(105,105,105) 50%, black 57.95% );
}    

30% {
  background:linear-gradient(45deg, black 42%, 	rgb(105,105,105) 50%, black 58% );
}    

30.5% {
  background:linear-gradient(45deg, black 41.95%, 	rgb(105,105,105) 50%, black 58.05% );
}    

31% {
  background:linear-gradient(45deg, black 41.9%, 	rgb(105,105,105) 50%, black 58.1% );
}    

31.5% {
  background:linear-gradient(45deg, black 41.85%, 	rgb(105,105,105) 50%, black 58.15% );
}    

32% {
  background:linear-gradient(45deg, black 41.8%, 	rgb(105,105,105) 50%, black 58.2% );
}    

32.5% {
  background:linear-gradient(45deg, black 41.75%, 	rgb(105,105,105) 50%, black 58.25% );
}    

33% {
  background:linear-gradient(45deg, black 41.7%, 	rgb(105,105,105) 50%, black 58.3% );
}    

33.5% {
  background:linear-gradient(45deg, black 41.65%, 	rgb(105,105,105) 50%, black 58.35% );
}    

34% {
  background:linear-gradient(45deg, black 41.6%, 	rgb(105,105,105) 50%, black 58.4% );
}    

34.5% {
  background:linear-gradient(45deg, black 41.55%, 	rgb(105,105,105) 50%, black 58.45% );
}    

35% {
  background:linear-gradient(45deg, black 41.5%, 	rgb(105,105,105) 50%, black 58.5% );
}    

35.5% {
  background:linear-gradient(45deg, black 41.45%, 	rgb(105,105,105) 50%, black 58.55% );
}    

36% {
  background:linear-gradient(45deg, black 41.4%, 	rgb(105,105,105) 50%, black 58.6% );
}    

36.5% {
  background:linear-gradient(45deg, black 41.35%, 	rgb(105,105,105) 50%, black 58.65% );
}    

37% {
  background:linear-gradient(45deg, black 41.3%, 	rgb(105,105,105) 50%, black 58.7% );
}    

37.5% {
  background:linear-gradient(45deg, black 41.25%, 	rgb(105,105,105) 50%, black 58.75% );
}    

38% {
  background:linear-gradient(45deg, black 41.2%, 	rgb(105,105,105) 50%, black 58.8% );
}    

38.5% {
  background:linear-gradient(45deg, black 41.15%, 	rgb(105,105,105) 50%, black 58.85% );
}    

39% {
  background:linear-gradient(45deg, black 41.1%, 	rgb(105,105,105) 50%, black 58.9% );
}    

39.5% {
  background:linear-gradient(45deg, black 41.05%, 	rgb(105,105,105) 50%, black 58.95% );
}    

40% {
  background:linear-gradient(45deg, black 41%, 	rgb(105,105,105) 50%, black 59% );
}    

40.5% {
  background:linear-gradient(45deg, black 40.95%, 	rgb(105,105,105) 50%, black 59.05% );
}    

41% {
  background:linear-gradient(45deg, black 40.9%, 	rgb(105,105,105) 50%, black 59.1% );
}    

41.5% {
  background:linear-gradient(45deg, black 40.85%, 	rgb(105,105,105) 50%, black 59.15% );
}    

42% {
  background:linear-gradient(45deg, black 40.8%, 	rgb(105,105,105) 50%, black 59.2% );
}    

42.5% {
  background:linear-gradient(45deg, black 40.75%, 	rgb(105,105,105) 50%, black 59.25% );
}    

43% {
  background:linear-gradient(45deg, black 40.7%, 	rgb(105,105,105) 50%, black 59.3% );
}    

43.5% {
  background:linear-gradient(45deg, black 40.65%, 	rgb(105,105,105) 50%, black 59.35% );
}    

44% {
  background:linear-gradient(45deg, black 40.6%, 	rgb(105,105,105) 50%, black 59.4% );
}    

44.5% {
  background:linear-gradient(45deg, black 40.55%, 	rgb(105,105,105) 50%, black 59.45% );
}    

45% {
  background:linear-gradient(45deg, black 40.5%, 	rgb(105,105,105) 50%, black 59.5% );
}    

45.5% {
  background:linear-gradient(45deg, black 40.45%, 	rgb(105,105,105) 50%, black 59.55% );
}    

46% {
  background:linear-gradient(45deg, black 40.4%, 	rgb(105,105,105) 50%, black 59.6% );
}    

46.5% {
  background:linear-gradient(45deg, black 40.35%, 	rgb(105,105,105) 50%, black 59.65% );
}    

47% {
  background:linear-gradient(45deg, black 40.3%, 	rgb(105,105,105) 50%, black 59.7% );
}    

47.5% {
  background:linear-gradient(45deg, black 40.25%, 	rgb(105,105,105) 50%, black 59.75% );
}    

48% {
  background:linear-gradient(45deg, black 40.2%, 	rgb(105,105,105) 50%, black 59.8% );
}    

48.5% {
  background:linear-gradient(45deg, black 40.15%, 	rgb(105,105,105) 50%, black 59.85% );
}    

49% {
  background:linear-gradient(45deg, black 40.1%, 	rgb(105,105,105) 50%, black 59.9% );
}    

49.5% {
  background:linear-gradient(45deg, black 40.05%, 	rgb(105,105,105) 50%, black 59.95% );
}    

50% {
  background:linear-gradient(45deg, black 40%, 	rgb(105,105,105) 50%, black 60% );
}    

50.5% {
  background:linear-gradient(45deg, black 39.95%, 	rgb(105,105,105) 50%, black 60.05% );
}    

51% {
  background:linear-gradient(45deg, black 39.9%, 	rgb(105,105,105) 50%, black 60.1% );
}    

51.5% {
  background:linear-gradient(45deg, black 39.85%, 	rgb(105,105,105) 50%, black 60.15% );
}    

52% {
  background:linear-gradient(45deg, black 39.8%, 	rgb(105,105,105) 50%, black 60.2% );
}    

52.5% {
  background:linear-gradient(45deg, black 39.75%, 	rgb(105,105,105) 50%, black 60.25% );
}    

53% {
  background:linear-gradient(45deg, black 39.7%, 	rgb(105,105,105) 50%, black 60.3% );
}    

53.5% {
  background:linear-gradient(45deg, black 39.65%, 	rgb(105,105,105) 50%, black 60.35% );
}    

54% {
  background:linear-gradient(45deg, black 39.6%, 	rgb(105,105,105) 50%, black 60.4% );
}    

54.5% {
  background:linear-gradient(45deg, black 39.55%, 	rgb(105,105,105) 50%, black 60.45% );
}    

55% {
  background:linear-gradient(45deg, black 39.5%, 	rgb(105,105,105) 50%, black 60.5% );
}    

55.5% {
  background:linear-gradient(45deg, black 39.45%, 	rgb(105,105,105) 50%, black 60.55% );
}    

56% {
  background:linear-gradient(45deg, black 39.4%, 	rgb(105,105,105) 50%, black 60.6% );
}    

56.5% {
  background:linear-gradient(45deg, black 39.35%, 	rgb(105,105,105) 50%, black 60.65% );
}    

57% {
  background:linear-gradient(45deg, black 39.3%, 	rgb(105,105,105) 50%, black 60.7% );
}    

57.5% {
  background:linear-gradient(45deg, black 39.25%, 	rgb(105,105,105) 50%, black 60.75% );
}    

58% {
  background:linear-gradient(45deg, black 39.2%, 	rgb(105,105,105) 50%, black 60.8% );
}    

58.5% {
  background:linear-gradient(45deg, black 39.15%, 	rgb(105,105,105) 50%, black 60.85% );
}    

59% {
  background:linear-gradient(45deg, black 39.1%, 	rgb(105,105,105) 50%, black 60.9% );
}    

59.5% {
  background:linear-gradient(45deg, black 39.05%, 	rgb(105,105,105) 50%, black 60.95% );
}    

60% {
  background:linear-gradient(45deg, black 39%, 	rgb(105,105,105) 50%, black 61% );
}    

60.5% {
  background:linear-gradient(45deg, black 38.95%, 	rgb(105,105,105) 50%, black 61.05% );
}    

61% {
  background:linear-gradient(45deg, black 38.9%, 	rgb(105,105,105) 50%, black 61.1% );
}    

61.5% {
  background:linear-gradient(45deg, black 38.85%, 	rgb(105,105,105) 50%, black 61.15% );
}    

62% {
  background:linear-gradient(45deg, black 38.8%, 	rgb(105,105,105) 50%, black 61.2% );
}    

62.5% {
  background:linear-gradient(45deg, black 38.75%, 	rgb(105,105,105) 50%, black 61.25% );
}    

63% {
  background:linear-gradient(45deg, black 38.7%, 	rgb(105,105,105) 50%, black 61.3% );
}    

63.5% {
  background:linear-gradient(45deg, black 38.65%, 	rgb(105,105,105) 50%, black 61.35% );
}    

64% {
  background:linear-gradient(45deg, black 38.6%, 	rgb(105,105,105) 50%, black 61.4% );
}    

64.5% {
  background:linear-gradient(45deg, black 38.55%, 	rgb(105,105,105) 50%, black 61.45% );
}    

65% {
  background:linear-gradient(45deg, black 38.5%, 	rgb(105,105,105) 50%, black 61.5% );
}    

65.5% {
  background:linear-gradient(45deg, black 38.45%, 	rgb(105,105,105) 50%, black 61.55% );
}    

66% {
  background:linear-gradient(45deg, black 38.4%, 	rgb(105,105,105) 50%, black 61.6% );
}    

66.5% {
  background:linear-gradient(45deg, black 38.35%, 	rgb(105,105,105) 50%, black 61.65% );
}    

67% {
  background:linear-gradient(45deg, black 38.3%, 	rgb(105,105,105) 50%, black 61.7% );
}    

67.5% {
  background:linear-gradient(45deg, black 38.25%, 	rgb(105,105,105) 50%, black 61.75% );
}    

68% {
  background:linear-gradient(45deg, black 38.2%, 	rgb(105,105,105) 50%, black 61.8% );
}    

68.5% {
  background:linear-gradient(45deg, black 38.15%, 	rgb(105,105,105) 50%, black 61.85% );
}    

69% {
  background:linear-gradient(45deg, black 38.1%, 	rgb(105,105,105) 50%, black 61.9% );
}    

69.5% {
  background:linear-gradient(45deg, black 38.05%, 	rgb(105,105,105) 50%, black 61.95% );
}    

70% {
  background:linear-gradient(45deg, black 38%, 	rgb(105,105,105) 50%, black 62% );
}    

70.5% {
  background:linear-gradient(45deg, black 37.95%, 	rgb(105,105,105) 50%, black 62.05% );
}    

71% {
  background:linear-gradient(45deg, black 37.9%, 	rgb(105,105,105) 50%, black 62.1% );
}    

71.5% {
  background:linear-gradient(45deg, black 37.85%, 	rgb(105,105,105) 50%, black 62.15% );
}    

72% {
  background:linear-gradient(45deg, black 37.8%, 	rgb(105,105,105) 50%, black 62.2% );
}    

72.5% {
  background:linear-gradient(45deg, black 37.75%, 	rgb(105,105,105) 50%, black 62.25% );
}    

73% {
  background:linear-gradient(45deg, black 37.7%, 	rgb(105,105,105) 50%, black 62.3% );
}    

73.5% {
  background:linear-gradient(45deg, black 37.65%, 	rgb(105,105,105) 50%, black 62.35% );
}    

74% {
  background:linear-gradient(45deg, black 37.6%, 	rgb(105,105,105) 50%, black 62.4% );
}    

74.5% {
  background:linear-gradient(45deg, black 37.55%, 	rgb(105,105,105) 50%, black 62.45% );
}    

75% {
  background:linear-gradient(45deg, black 37.5%, 	rgb(105,105,105) 50%, black 62.5% );
}    

75.5% {
  background:linear-gradient(45deg, black 37.45%, 	rgb(105,105,105) 50%, black 62.55% );
}    

76% {
  background:linear-gradient(45deg, black 37.4%, 	rgb(105,105,105) 50%, black 62.6% );
}    

76.5% {
  background:linear-gradient(45deg, black 37.35%, 	rgb(105,105,105) 50%, black 62.65% );
}    

77% {
  background:linear-gradient(45deg, black 37.3%, 	rgb(105,105,105) 50%, black 62.7% );
}    

77.5% {
  background:linear-gradient(45deg, black 37.25%, 	rgb(105,105,105) 50%, black 62.75% );
}    

78% {
  background:linear-gradient(45deg, black 37.2%, 	rgb(105,105,105) 50%, black 62.8% );
}    

78.5% {
  background:linear-gradient(45deg, black 37.15%, 	rgb(105,105,105) 50%, black 62.85% );
}    

79% {
  background:linear-gradient(45deg, black 37.1%, 	rgb(105,105,105) 50%, black 62.9% );
}    

79.5% {
  background:linear-gradient(45deg, black 37.05%, 	rgb(105,105,105) 50%, black 62.95% );
}    

80% {
  background:linear-gradient(45deg, black 37%, 	rgb(105,105,105) 50%, black 63% );
}    

80.5% {
  background:linear-gradient(45deg, black 36.95%, 	rgb(105,105,105) 50%, black 63.05% );
}    

81% {
  background:linear-gradient(45deg, black 36.9%, 	rgb(105,105,105) 50%, black 63.1% );
}    

81.5% {
  background:linear-gradient(45deg, black 36.85%, 	rgb(105,105,105) 50%, black 63.15% );
}    

82% {
  background:linear-gradient(45deg, black 36.8%, 	rgb(105,105,105) 50%, black 63.2% );
}    

82.5% {
  background:linear-gradient(45deg, black 36.75%, 	rgb(105,105,105) 50%, black 63.25% );
}    

83% {
  background:linear-gradient(45deg, black 36.7%, 	rgb(105,105,105) 50%, black 63.3% );
}    

83.5% {
  background:linear-gradient(45deg, black 36.65%, 	rgb(105,105,105) 50%, black 63.35% );
}    

84% {
  background:linear-gradient(45deg, black 36.6%, 	rgb(105,105,105) 50%, black 63.4% );
}    

84.5% {
  background:linear-gradient(45deg, black 36.55%, 	rgb(105,105,105) 50%, black 63.45% );
}    

85% {
  background:linear-gradient(45deg, black 36.5%, 	rgb(105,105,105) 50%, black 63.5% );
}    

85.5% {
  background:linear-gradient(45deg, black 36.45%, 	rgb(105,105,105) 50%, black 63.55% );
}    

86% {
  background:linear-gradient(45deg, black 36.4%, 	rgb(105,105,105) 50%, black 63.6% );
}    

86.5% {
  background:linear-gradient(45deg, black 36.35%, 	rgb(105,105,105) 50%, black 63.65% );
}    

87% {
  background:linear-gradient(45deg, black 36.3%, 	rgb(105,105,105) 50%, black 63.7% );
}    

87.5% {
  background:linear-gradient(45deg, black 36.25%, 	rgb(105,105,105) 50%, black 63.75% );
}    

88% {
  background:linear-gradient(45deg, black 36.2%, 	rgb(105,105,105) 50%, black 63.8% );
}    

88.5% {
  background:linear-gradient(45deg, black 36.15%, 	rgb(105,105,105) 50%, black 63.85% );
}    

89% {
  background:linear-gradient(45deg, black 36.1%, 	rgb(105,105,105) 50%, black 63.9% );
}    

89.5% {
  background:linear-gradient(45deg, black 36.05%, 	rgb(105,105,105) 50%, black 63.95% );
}    

90% {
  background:linear-gradient(45deg, black 36%, 	rgb(105,105,105) 50%, black 64% );
}    

90.5% {
  background:linear-gradient(45deg, black 35.95%, 	rgb(105,105,105) 50%, black 64.05% );
}    

91% {
  background:linear-gradient(45deg, black 35.9%, 	rgb(105,105,105) 50%, black 64.1% );
}    

91.5% {
  background:linear-gradient(45deg, black 35.85%, 	rgb(105,105,105) 50%, black 64.15% );
}    

92% {
  background:linear-gradient(45deg, black 35.8%, 	rgb(105,105,105) 50%, black 64.2% );
}    

92.5% {
  background:linear-gradient(45deg, black 35.75%, 	rgb(105,105,105) 50%, black 64.25% );
}    

93% {
  background:linear-gradient(45deg, black 35.7%, 	rgb(105,105,105) 50%, black 64.3% );
}    

93.5% {
  background:linear-gradient(45deg, black 35.65%, 	rgb(105,105,105) 50%, black 64.35% );
}    

94% {
  background:linear-gradient(45deg, black 35.6%, 	rgb(105,105,105) 50%, black 64.4% );
}    

94.5% {
  background:linear-gradient(45deg, black 35.55%, 	rgb(105,105,105) 50%, black 64.45% );
}    

95% {
  background:linear-gradient(45deg, black 35.5%, 	rgb(105,105,105) 50%, black 64.5% );
}    

95.5% {
  background:linear-gradient(45deg, black 35.45%, 	rgb(105,105,105) 50%, black 64.55% );
}    

96% {
  background:linear-gradient(45deg, black 35.4%, 	rgb(105,105,105) 50%, black 64.6% );
}    

96.5% {
  background:linear-gradient(45deg, black 35.35%, 	rgb(105,105,105) 50%, black 64.65% );
}    

97% {
  background:linear-gradient(45deg, black 35.3%, 	rgb(105,105,105) 50%, black 64.7% );
}    

97.5% {
  background:linear-gradient(45deg, black 35.25%, 	rgb(105,105,105) 50%, black 64.75% );
}    

98% {
  background:linear-gradient(45deg, black 35.2%, 	rgb(105,105,105) 50%, black 64.8% );
}    

98.5% {
  background:linear-gradient(45deg, black 35.15%, 	rgb(105,105,105) 50%, black 64.85% );
}    

99% {
  background:linear-gradient(45deg, black 35.1%, 	rgb(105,105,105) 50%, black 64.9% );
}    

99.5% {
  background:linear-gradient(45deg, black 35.05%, 	rgb(105,105,105) 50%, black 64.95% );
}    

100% {
  background:linear-gradient(45deg, black 35%, 	rgb(105,105,105) 50%, black 65% );
}    


`

const ContactScreen=styled.div`
  overflow:hidden;
  opacity: ${props=>props.isScrolledContactMe ? 1:0};
  z-index:${props=>props.isScrolledContactMe ? 0 : -4};
  background-color:yellow;

  display:flex;
  @media screen and (min-width: 768px) {
    background-image: linear-gradient(to bottom left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${props=>props.image});
    background-size: cover;
    background-position: center;
  }
  @media screen and (max-width: 767px) {
    background-image: linear-gradient(to bottom left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${props=>props.contact_me_pic_mobile});
    background-size: cover;
    background-position: center;
  }
  
  
  transition:opacity 0.5s ease;
  -webkit-transition:opacity 0.5s ease;

  flex-direction:row;
  top:0;
  left:0;
  height:100%;
  width:100%;
  position:fixed;

`
const GradientScreen=styled.div`
  overflow:hidden;
  opacity: ${props=>props.isScrolledExtraCurr && !props.isScrolledContactMe? 1:0};
  z-index:${props=>props.isScrolledExtraCurr  && !props.isScrolledContactMe? 0 : -3};

  display:flex;
  
  animation: ${GradientAnimation} 5s alternate infinite;
  transition:opacity 0.5s ease;
  -webkit-transition:opacity 0.5s ease;

  flex-direction:row;
  gap:1vh;
  top:0;
  left:0;
  height:100%;
  width:100%;
  position:fixed;
`

const close_course_anim=keyframes`
  0%{
    opacity:1;
    transform: translateX(0);
  }
  100%{
    opacity:0;
    transform: translateX(-100%);

  }

`
const show_course_anim=keyframes`
  0%{
    opacity:0;
    transform: translateX(100%);
  }
  100%{
    opacity:1;
    transform: translateX(0);
  }

`

const ExtraCurricularContainer=styled.div`
  position:relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-bottom:10vw;
  overflow:hidden;

  ul {
    list-style-type: none;
    padding-left: 1vw;

    li {
      position: relative;
      color: white;
      transition: color 0.5s ease;
      -webkit-transition: color 0.5s ease;

      &:before {
        content: '•';
        margin-right: 1vw; 
        @media screen and (min-width: 768px) {
          font-size: 3vh;
        }
        @media screen and (max-width: 767px) {
          font-size: 2vh;
        } 
      }
    }
  }
  span{
    color: white;
    transition: color 0.5s ease;
    -webkit-transition: color 0.5s ease;
  }

  >div:first-of-type{
    @media screen and (min-width: 768px) {
      margin-left:10vw;
      margin-right:10vw;
      margin-top:10vw;
      gap:4vh;
      width:40vw;
    }
    @media screen and (max-width: 767px) {
      margin-left:5vw;
      margin-right:5vw;
      margin-top:5vw;
      gap:3vh;
      width:70vw;
    } 
    display:flex;
    flex-direction:column;
    margin-bottom:5vw;
    >span:nth-of-type(1){
      @media screen and (min-width: 768px) {
        font-size:5vh;
      }
      @media screen and (max-width: 767px) {
        font-size:4vh;
      } 
    }
    >span:nth-of-type(2){
      @media screen and (min-width: 768px) {
        font-size:6vh;
      }
      @media screen and (max-width: 767px) {
        font-size:4vh;
      }
      display:flex;
      font-weight:bold;
    }
     >span:nth-of-type(3){
      @media screen and (min-width: 768px) {
        font-size:5vh;
      }
      @media screen and (max-width: 767px) {
        font-size:4vh;
      } 
    }
  }
  >div:nth-of-type(even){
    @media screen and (min-width: 768px) {
      margin-left:10vw;
      margin-right:10vw;
    }
    @media screen and (max-width: 767px) {
      margin-left:5vw;
      margin-right:5vw;
    }
    display:flex;
    flex-direction:column;
    margin-bottom:5vw;
    >span:nth-of-type(1){
      @media screen and (min-width: 768px) {
        font-size:4vh;
      }
      @media screen and (max-width: 767px) {
        font-size:3vh;
      }
    }
  }
  >div:nth-of-type(3){
    display:flex;
    margin-bottom:6vw;
    @media screen and (max-width: 767px) {
      margin-top:5vw;
    }
    

    @media screen and (min-width: 768px) {
      margin-left:10vw;
      margin-right:10vw;
      flex-direction:row;
    }
    @media screen and (max-width: 767px) {
      margin-left:5vw;
      margin-right:5vw;
      flex-direction:column;
      align-items:center;

      >div:first-of-type{
        margin-bottom:12vw;
      }
    }
    
  }
  >div:nth-of-type(5){
    @media screen and (min-width: 768px) {
      margin-left:10vw;
      margin-right:10vw;
      flex-direction:row;
    }
    @media screen and (max-width: 767px) {
      margin-left:5vw;
      margin-right:5vw;
      flex-direction:column;
    }
    margin-bottom:5vw;
    display:flex;

    img {
      @media screen and (min-width: 768px) {
        width:22vw;
        height:22vw;
      }
      @media screen and (max-width: 767px) {
        width:70vw;
        height:70vw;
        align-self:center;
      }
    }
     >div{
        @media screen and (min-width: 768px) {
          margin-left:6vw;
        }
        @media screen and (max-width: 767px) {
          margin-top:6vw;
        }
        display:flex;
        flex-direction:column;
        justify-content:center;
        >span:first-of-type{
          @media screen and (min-width: 768px) {
            font-size:4vh;
            margin-bottom:3vh;
          }
          @media screen and (max-width: 767px) {
            font-size:3vh;
            margin-bottom:2vh;
          }
        }

        span:not(:first-of-type){
          @media screen and (min-width: 768px) {
            margin-bottom:1vh;
            ont-size:3vh;
          }
          @media screen and (max-width: 767px) {
            margin-bottom:0.5vh;
            font-size:2vh;
          }
        }
     }
  }
  >div:nth-of-type(7){
    margin-bottom:5vw;
    @media screen and (min-width: 768px) {
      margin-left:10vw;
      margin-right:10vw;
      flex-direction:row;
    }
    @media screen and (max-width: 767px) {
      margin-left:5vw;
      margin-right:5vw;
      flex-direction:column;
      gap:2vh;
    }
    display:flex;
    div{
      flex:1;
      display:flex;
      flex-direction:column;
      align-items:center;
      
      >span{
        @media screen and (min-width: 768px) {
          font-size:3vh;
        }
        @media screen and (max-width: 767px) {
          font-size:2vh;
        }
      }
      >img{
        @media screen and (min-width: 768px) {
          width: 20vw;
          height: 20vw;
        }
        @media screen and (max-width: 767px) {
          width: 60vw;
          height: 60vw;
        }
        margin-bottom:1vh;
      }
    }
  }
`

const CourseInfoContainer=styled.div`
  display:flex;
  height:100%;
  overflow-x:hidden;
  @media screen and (min-width: 768px) {
    margin-left:8vw;
  }
  @media screen and (max-width: 767px) {
    margin-left:0vw;
    width:100%;
  }
  
  flex:1;
  box-sizing:border-box;
`
const CourseInfo=styled.div`
  display:flex;
  min-width:100%;
  position:relative;
  left:${props=>(props.x)*-100}%;
  flex-direction:column;
  justify-content:center;
  animation:${props=>props.show_course ? show_course_anim : close_course_anim } 2s ease-in-out forwards;

  >span:first-of-type{
    @media screen and (min-width: 768px) {
      font-size:4vh;
      margin-bottom:3vh;
    }
    @media screen and (max-width: 767px) {
      font-size:3vh;
      margin-bottom:2vh;
    }
  }
  a{
    text-decoration-color: white;
  }
  span:nth-of-type(2){
    font-size:0.5vh;
  }
`


const BlackScreen=styled.span`
  background-color:black;
  overflow:hidden;
  opacity:${props=> props.isApproachedSkills && !props.isScrolledExtraCurr  ? 1 : 0};
  z-index:${props=> props.isApproachedSkills && !props.isScrolledExtraCurr ? 0 : -2};

  display:flex;
 transition: opacity 0.5s ease;
  -webkit-transition: opacity 0.5s ease;
  flex-direction:row;
  gap:1vh;
  top:0;
  left:0;
  height:100%;
  width:100%;
  position:fixed;
`
const SkillsContainer=styled.div`
  position:relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  ul {
    list-style-type: none;
    padding-left: 1vw;

    li {
      position: relative;
      color: ${props=> (props.isApproachedSkills ) ? `white`: `black`};
      transition: color 0.5s ease;
      -webkit-transition: color 0.5s ease;

      &:before {
        content: '•';
        margin-right: 1vw; 
        @media screen and (min-width: 768px) {
          font-size: 3vh; ;
        }
        @media screen and (max-width: 767px) {
          font-size: 2vh; 
        }
      }
    }
  }
  span{
    color: ${props=> (props.isApproachedSkills) ? `white`: `black`};
    transition: color 0.5s ease;
    -webkit-transition: color 0.5s ease;
  }
  >div:first-of-type{
    @media screen and (min-width: 768px) {
      margin-left:10vw;
      margin-right:10vw;
      gap:4vh;
      margin-top:10vw;
      align-items:center;
    }
    @media screen and (max-width: 767px) {
      margin-left:5vw;
      margin-right:5vw;
      gap:3vh;
      margin-top:5vw;
      align-items:start;
    }
    display:flex;
    flex-direction:column;
    margin-bottom:5vw;
    >span:nth-of-type(1){
      @media screen and (min-width: 768px) {
        font-size:5vh;
      }
      @media screen and (max-width: 767px) {
        font-size:4vh;
      }
    }
    >span:nth-of-type(2){
      @media screen and (min-width: 768px) {
        font-size:6vh;
        text-align:center;
        justify-content:center;
        align-items:center;
      }
      @media screen and (max-width: 767px) {
        font-size:4vh;
        text-align:start;
        justify-content:start;
        align-items:center;
      }
      display:flex;
      font-weight:bold;
    }
     >span:nth-of-type(3){
      @media screen and (min-width: 768px) {
        font-size:5vh;
      }
      @media screen and (max-width: 767px) {
        font-size:4vh;
      }
    }
  }

  @media screen and (min-width: 768px) {
    >div:nth-of-type(2){
      margin-left:10vw;
      margin-right:10vw;
      display:flex;
      flex-direction:row;

      & div{
        flex:1;
        display:flex;
        flex-direction:column;
        >span:nth-of-type(1){
          font-weight:bold;
          font-size:3vh;
        }
      }
    }
    >div:nth-of-type(3){
      margin-left:10vw;
      margin-right:10vw;
      display:flex;
      flex-direction:row;

      & div{
        flex:1;
        display:flex;
        flex-direction:column;
        span{
          font-size:3vh;
        }
      }
    }
    >div:nth-of-type(4){
      margin-left:10vw;
      margin-right:10vw;
      margin-top:5vw;
      display:flex;
      flex-direction:row;
      justify-content:center;
      align-items:center;

      & div{
        flex:1;
        display:flex;
        flex-direction:column;
        >span:nth-of-type(1){
          font-weight:bold;
          font-size:3vh;
        }
      }
    }
    >div:nth-of-type(5){
      margin-left:10vw;
      margin-right:10vw;
      display:flex;
      flex-direction:row;

      & div{
        flex:1;
        display:flex;
        flex-direction:column;
        span{
          font-size:3vh;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    >div{
      display:flex;
      flex-direction:column;
      margin-left:5vw;
      margin-right:5vw;
      margin:1.5vh;
      gap:2vh;

      >div:nth-of-type(1){
        >span{
          font-size:3vh;
          font-weight:bold;
        }
      }
      >div:nth-of-type(2){
        span{
          font-size:2vh;
        }
      }
    }
  }
  
`



const FixedGearAnim=keyframes`
  0% { 
    transform: rotate(0deg);
  }

  1% { 
    transform: rotate(360deg);
  }
`

const FixedGear=styled.img`
  position:absolute;
  animation: ${FixedGearAnim} 30s linear infinite;
`



const RandomGear = React.memo(({ }) => {
  const genGearsFrames = () =>{
    let amplitude=Math.random()*window.innerWidth*0.08
    let props={};
    for (let i=0;i<100;i=i+1){
      props["x"+(i+1)]=amplitude*Math.cos(i);
      props["y"+(i+1)]=window.innerHeight/100 * i
    }
    return props;
  }
  let duration=Math.random()*(80-30)+30
  let topY=Math.random()*window.innerHeight*0.3
  return <AnimatedGear duration={duration} topY={topY} src={gear} alt={"gear"} animProps={genGearsFrames()} />;
});



const RandomGearAnim = (props) => keyframes` 
  0% { 
    transform: translate(0px, 0px) rotate(0deg);
  }

  1% { 
    transform: translate(${props.x1}px, ${props.y1}px) rotate(30deg);
  }

  2% { 
    transform: translate(${props.x2}px, ${props.y2}px) rotate(60deg);
  }

  3% { 
    transform: translate(${props.x3}px, ${props.y3}px) rotate(90deg);
  }

  4% { 
    transform: translate(${props.x4}px, ${props.y4}px) rotate(120deg);
  }

  5% { 
    transform: translate(${props.x5}px, ${props.y5}px) rotate(150deg);
  }

  6% { 
    transform: translate(${props.x6}px, ${props.y6}px) rotate(180deg);
  }

  7% { 
    transform: translate(${props.x7}px, ${props.y7}px) rotate(210deg);
  }

  8% { 
    transform: translate(${props.x8}px, ${props.y8}px) rotate(240deg);
  }

  9% { 
    transform: translate(${props.x9}px, ${props.y9}px) rotate(270deg);
  }

  10% { 
    transform: translate(${props.x10}px, ${props.y10}px) rotate(300deg);
  }

  11% { 
    transform: translate(${props.x11}px, ${props.y11}px) rotate(330deg);
  }

  12% { 
    transform: translate(${props.x12}px, ${props.y12}px) rotate(360deg);
  }

  13% { 
    transform: translate(${props.x13}px, ${props.y13}px) rotate(390deg);
  }

  14% { 
    transform: translate(${props.x14}px, ${props.y14}px) rotate(420deg);
  }

  15% { 
    transform: translate(${props.x15}px, ${props.y15}px) rotate(450deg);
  }

  16% { 
    transform: translate(${props.x16}px, ${props.y16}px) rotate(480deg);
  }

  17% { 
    transform: translate(${props.x17}px, ${props.y17}px) rotate(510deg);
  }

  18% { 
    transform: translate(${props.x18}px, ${props.y18}px) rotate(540deg);
  }

  19% { 
    transform: translate(${props.x19}px, ${props.y19}px) rotate(570deg);
  }

  20% { 
    transform: translate(${props.x20}px, ${props.y20}px) rotate(600deg);
  }

  21% { 
    transform: translate(${props.x21}px, ${props.y21}px) rotate(630deg);
  }

  22% { 
    transform: translate(${props.x22}px, ${props.y22}px) rotate(660deg);
  }

  23% { 
    transform: translate(${props.x23}px, ${props.y23}px) rotate(690deg);
  }

  24% { 
    transform: translate(${props.x24}px, ${props.y24}px) rotate(720deg);
  }

  25% { 
    transform: translate(${props.x25}px, ${props.y25}px) rotate(750deg);
  }

  26% { 
    transform: translate(${props.x26}px, ${props.y26}px) rotate(780deg);
  }

  27% { 
    transform: translate(${props.x27}px, ${props.y27}px) rotate(810deg);
  }

  28% { 
    transform: translate(${props.x28}px, ${props.y28}px) rotate(840deg);
  }

  29% { 
    transform: translate(${props.x29}px, ${props.y29}px) rotate(870deg);
  }

  30% { 
    transform: translate(${props.x30}px, ${props.y30}px) rotate(900deg);
  }

  31% { 
    transform: translate(${props.x31}px, ${props.y31}px) rotate(930deg);
  }

  32% { 
    transform: translate(${props.x32}px, ${props.y32}px) rotate(960deg);
  }

  33% { 
    transform: translate(${props.x33}px, ${props.y33}px) rotate(990deg);
  }

  34% { 
    transform: translate(${props.x34}px, ${props.y34}px) rotate(1020deg);
  }

  35% { 
    transform: translate(${props.x35}px, ${props.y35}px) rotate(1050deg);
  }

  36% { 
    transform: translate(${props.x36}px, ${props.y36}px) rotate(1080deg);
  }

  37% { 
    transform: translate(${props.x37}px, ${props.y37}px) rotate(1110deg);
  }

  38% { 
    transform: translate(${props.x38}px, ${props.y38}px) rotate(1140deg);
  }

  39% { 
    transform: translate(${props.x39}px, ${props.y39}px) rotate(1170deg);
  }

  40% { 
    transform: translate(${props.x40}px, ${props.y40}px) rotate(1200deg);
  }

  41% { 
    transform: translate(${props.x41}px, ${props.y41}px) rotate(1230deg);
  }

  42% { 
    transform: translate(${props.x42}px, ${props.y42}px) rotate(1260deg);
  }

  43% { 
    transform: translate(${props.x43}px, ${props.y43}px) rotate(1290deg);
  }

  44% { 
    transform: translate(${props.x44}px, ${props.y44}px) rotate(1320deg);
  }

  45% { 
    transform: translate(${props.x45}px, ${props.y45}px) rotate(1350deg);
  }

  46% { 
    transform: translate(${props.x46}px, ${props.y46}px) rotate(1380deg);
  }

  47% { 
    transform: translate(${props.x47}px, ${props.y47}px) rotate(1410deg);
  }

  48% { 
    transform: translate(${props.x48}px, ${props.y48}px) rotate(1440deg);
  }

  49% { 
    transform: translate(${props.x49}px, ${props.y49}px) rotate(1470deg);
  }

  50% { 
    transform: translate(${props.x50}px, ${props.y50}px) rotate(1500deg);
  }

  51% { 
    transform: translate(${props.x51}px, ${props.y51}px) rotate(1530deg);
  }

  52% { 
    transform: translate(${props.x52}px, ${props.y52}px) rotate(1560deg);
  }

  53% { 
    transform: translate(${props.x53}px, ${props.y53}px) rotate(1590deg);
  }

  54% { 
    transform: translate(${props.x54}px, ${props.y54}px) rotate(1620deg);
  }

  55% { 
    transform: translate(${props.x55}px, ${props.y55}px) rotate(1650deg);
  }

  56% { 
    transform: translate(${props.x56}px, ${props.y56}px) rotate(1680deg);
  }

  57% { 
    transform: translate(${props.x57}px, ${props.y57}px) rotate(1710deg);
  }

  58% { 
    transform: translate(${props.x58}px, ${props.y58}px) rotate(1740deg);
  }

  59% { 
    transform: translate(${props.x59}px, ${props.y59}px) rotate(1770deg);
  }

  60% { 
    transform: translate(${props.x60}px, ${props.y60}px) rotate(1800deg);
  }

  61% { 
    transform: translate(${props.x61}px, ${props.y61}px) rotate(1830deg);
  }

  62% { 
    transform: translate(${props.x62}px, ${props.y62}px) rotate(1860deg);
  }

  63% { 
    transform: translate(${props.x63}px, ${props.y63}px) rotate(1890deg);
  }

  64% { 
    transform: translate(${props.x64}px, ${props.y64}px) rotate(1920deg);
  }

  65% { 
    transform: translate(${props.x65}px, ${props.y65}px) rotate(1950deg);
  }

  66% { 
    transform: translate(${props.x66}px, ${props.y66}px) rotate(1980deg);
  }

  67% { 
    transform: translate(${props.x67}px, ${props.y67}px) rotate(2010deg);
  }

  68% { 
    transform: translate(${props.x68}px, ${props.y68}px) rotate(2040deg);
  }

  69% { 
    transform: translate(${props.x69}px, ${props.y69}px) rotate(2070deg);
  }

  70% { 
    transform: translate(${props.x70}px, ${props.y70}px) rotate(2100deg);
  }

  71% { 
    transform: translate(${props.x71}px, ${props.y71}px) rotate(2130deg);
  }

  72% { 
    transform: translate(${props.x72}px, ${props.y72}px) rotate(2160deg);
  }

  73% { 
    transform: translate(${props.x73}px, ${props.y73}px) rotate(2190deg);
  }

  74% { 
    transform: translate(${props.x74}px, ${props.y74}px) rotate(2220deg);
  }

  75% { 
    transform: translate(${props.x75}px, ${props.y75}px) rotate(2250deg);
  }

  76% { 
    transform: translate(${props.x76}px, ${props.y76}px) rotate(2280deg);
  }

  77% { 
    transform: translate(${props.x77}px, ${props.y77}px) rotate(2310deg);
  }

  78% { 
    transform: translate(${props.x78}px, ${props.y78}px) rotate(2340deg);
  }

  79% { 
    transform: translate(${props.x79}px, ${props.y79}px) rotate(2370deg);
  }

  80% { 
    transform: translate(${props.x80}px, ${props.y80}px) rotate(2400deg);
  }

  81% { 
    transform: translate(${props.x81}px, ${props.y81}px) rotate(2430deg);
  }

  82% { 
    transform: translate(${props.x82}px, ${props.y82}px) rotate(2460deg);
  }

  83% { 
    transform: translate(${props.x83}px, ${props.y83}px) rotate(2490deg);
  }

  84% { 
    transform: translate(${props.x84}px, ${props.y84}px) rotate(2520deg);
  }

  85% { 
    transform: translate(${props.x85}px, ${props.y85}px) rotate(2550deg);
  }

  86% { 
    transform: translate(${props.x86}px, ${props.y86}px) rotate(2580deg);
  }

  87% { 
    transform: translate(${props.x87}px, ${props.y87}px) rotate(2610deg);
  }

  88% { 
    transform: translate(${props.x88}px, ${props.y88}px) rotate(2640deg);
  }

  89% { 
    transform: translate(${props.x89}px, ${props.y89}px) rotate(2670deg);
  }

  90% { 
    transform: translate(${props.x90}px, ${props.y90}px) rotate(2700deg);
  }

  91% { 
    transform: translate(${props.x91}px, ${props.y91}px) rotate(2730deg);
  }

  92% { 
    transform: translate(${props.x92}px, ${props.y92}px) rotate(2760deg);
  }

  93% { 
    transform: translate(${props.x93}px, ${props.y93}px) rotate(2790deg);
  }

  94% { 
    transform: translate(${props.x94}px, ${props.y94}px) rotate(2820deg);
  }

  95% { 
    transform: translate(${props.x95}px, ${props.y95}px) rotate(2850deg);
  }

  96% { 
    transform: translate(${props.x96}px, ${props.y96}px) rotate(2880deg);
  }

  97% { 
    transform: translate(${props.x97}px, ${props.y97}px) rotate(2910deg);
  }

  98% { 
    transform: translate(${props.x98}px, ${props.y98}px) rotate(2940deg);
  }

  99% { 
    transform: translate(${props.x99}px, ${props.y99}px) rotate(2970deg);
  }

  100% { 
    transform: translate(${props.x100}px, ${props.y100}px) rotate(3000deg);
  }
`;



const AnimatedGear=styled.img`
  position:relative;
  top:${props=>props.topY}px;
  @media screen and (min-width: 768px) {
    width:3vw;
    height:3vw;
  }
  @media screen and (max-width: 767px) {
    width:6vw;
    height:6vw;
  }
  animation: ${props=>RandomGearAnim(props.animProps)} ${props=>props.duration}s linear infinite;
`





const StAndrews=styled.div`
  display:flex;
  flex:1;
  @media screen and (min-width: 768px) {
    flex-direction:row;
  }
  @media screen and (max-width: 767px) {
    flex-direction:column;
  }
  img { 
    width:45%;
    height:auto;
    @media screen and (min-width: 768px) {
      position:sticky;
      top: 20vh;
      margin-bottom: 10vh;
    }
  }
  div:nth-of-type(1){
    @media screen and (min-width: 768px) {
      margin-left:10vw;
      flex:5;
      display:flex;
      flex-direction:column;
    }
    @media screen and (max-width: 767px) {
      display:flex;
      flex-direction:column;
      align-items:center;
      justify-content:center;
      margin-right:5vw;
      margin-left:5vw;
      margin-bottom:5vw;
      margin-top:5vw;
      flex:4;
    }
  }
  div:nth-of-type(2){
    
    @media screen and (min-width: 768px) {
      display:flex;
      flex-direction:column;
      align-items:center;
      margin-right:10vw;
      flex:4;
    }
    @media screen and (max-width: 767px) {
      margin-left:5vw;
      margin-right:5vw;
      flex:5;
      display:flex;
      flex-direction:column;
    }

  }
`;

const Fettes=styled.div`
  display:flex;
  flex:1;
  @media screen and (min-width: 768px) {
    flex-direction:row;
  }
  @media screen and (max-width: 767px) {
    flex-direction:column;
  }
  margin-top:5vw;
  img {
    width:70%;
    height:auto;
    @media screen and (max-width: 767px) {
      transition: margin-top 0.2s ease-in-out;
      -webkit-transition: margin-top 0.2s ease-in-out;
    }
  }
  div:nth-of-type(1){
    flex:4;
    display:flex;
    flex-direction:column;
    @media screen and (min-width: 768px) {
      margin-left:10vw;
    }
    @media screen and (max-width: 767px) {
      margin:5vw;
      justify-content:center;
      align-items:center;
    }
  }
  div:nth-of-type(2){
    @media screen and (min-width: 768px) {
      margin-right:10vw;
      margin-left:5vw;
    }
    @media screen and (max-width: 767px) {
      margin-right:5vw;
      margin-left:5vw;
    }
    flex:5;
    display:flex;
    flex-direction:column;
  }
`

const EducationContainer=styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  @media (max-width: 767px) {
    overflow-x:hidden;
    overflow-y: auto;
  }
  
  >div:first-of-type {
    display:flex;
    justify-content:center;
    @media (min-width: 768px) {
      margin: 10vw;
    }
    @media (max-width: 767px) {
      margin: 5vw;
    }
    span{
      @media (min-width: 768px) {
        font-size:6vh;
      }
      @media (max-width: 767px) {
        font-size:5vh;
      }
    }
  }

  div .education-info{
    >span:not(:nth-of-type(1)){
      font-weight:bold;
      @media (min-width: 768px) {
        font-size:3vh;
      }
      @media (max-width: 767px) {
        font-size:2vh;
      }
    }
    >span:nth-of-type(1){
      font-weight:bold;
      @media (min-width: 768px) {
        font-size:4vh;
        margin-bottom:3vh;
      }
      @media (max-width: 767px) {
        font-size:3vh;
        margin-bottom:2vh;
      }
    }
    span{
      @media (min-width: 768px) {
        font-size:3vh;
      }
      @media (max-width: 767px) {
        font-size:2vh;
      }
    }
  }
  ul {
    list-style-type: none;
    @media (min-width: 768px) {
      padding-left: 1vw;
    }
    @media (max-width: 767px) {
      padding-left: 1vw;
    }

    li {
      position: relative;

      &:before {
        content: '•';
        @media (min-width: 768px) {
          margin-right: 1vw; 
          font-size: 3vh; 
        }
        @media (max-width: 767px) {
          margin-right: 1vw; 
          font-size: 2vh; 
        }
      }
    }
  }
`;

const AboutContainer=styled.div`
  display: flex;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  width: 100vw;

  #left-about {
    display:flex;
    flex-direction:column;
    flex:1;
    justify-content:center;
    align-items:start;
    margin-left:10vw;
    @media screen and (min-width: 768px) {
      gap:4vh;
    }
    @media screen and (max-width: 767px) {
      margin-bottom:4vh;
      gap:3vh;
    }
    @media screen and (min-width: 768px) {
      margin-left:10vw;
    }
    @media screen and (max-width: 767px) {
      margin-left:5vw;
    }
    
    div:nth-of-type(1){
      span {
        @media screen and (min-width: 768px) {
          font-size:5vh;
        }
        @media screen and (max-width: 767px) {
          font-size:4vh;
        }
      }
    }
    div:nth-of-type(2){
      display:flex;
      flex-direction:column;
      span {
        font-weight:bold;
        @media screen and (min-width: 768px) {
          font-size:6vh;
        }
        @media (max-width: 767px) {
          font-size:5vh;
        }
      }
    }
    div:nth-of-type(3){
      span {
        @media screen and (min-width: 768px) {
          font-size:5vh;
        }
        @media screen and (max-width: 767px) {
          font-size:4vh;
        }
      }
    }
  }
  #right-about {
    display:flex;
    flex:1;
    flex-direction:column;
    @media screen and (min-width: 768px) {
      gap:2.5vh;
      margin-right:10vw;
    }
    @media screen and (max-width: 767px) {
      gap:4vh;
      margin-right:5vw;
      margin-left:5vw;
    }

    div {
        span:nth-of-type(odd){
          font-weight:bold;
        }
    }
    
  }
  span {
    @media screen and (min-width: 768px) {
      font-size:3vh;
    }
    @media screen and (max-width: 767px) {
      font-size:2vh;
    }
    color: ${props => props.isScrolledAbout ? 'black' : 'white'};
    transition:  color 0.5s ease;
    -webkit-transition:  color 0.5s ease;
  };

`

const StartContainer = styled.div`
  overflow:hidden;
  display: flex;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  height: 100vh;
  width: 100vw;
  span {
    color: ${props => props.isScrolledAbout ? 'black' : 'white'};
    transition:  color 0.5s ease;
    -webkit-transition:  color 0.5s ease;
  };
  
`;


const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const BackgroundImage = styled.div`
  content: '';
  position: fixed;
  z-index:-1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 767px) {
    overflow-x:hidden;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(to bottom left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${props=>props.main_photo_mobile});
    
  }
  @media screen and  (min-width: 768px) {
    background-size: cover;
    background-image: linear-gradient(to bottom left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${props=>props.image_back});
  }
  animation: ${props => props.isScrolledAbout ? fadeOut : fadeIn} 0.5s ease forwards;
`;
  
const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    ${'' /* overflow-x:hidden; */}
  }
`;
